// extracted by mini-css-extract-plugin
export var aboutMainCircleBlurBlue = "Authors-module--aboutMainCircleBlurBlue--WpW1O";
export var aboutMainCircleBlurGreen = "Authors-module--aboutMainCircleBlurGreen--hsItu";
export var aboutMainCircleBlurGreenlower = "Authors-module--aboutMainCircleBlurGreenlower--7HfwP";
export var aboutMainCircleBlurGreenupper = "Authors-module--aboutMainCircleBlurGreenupper--z43uD";
export var authorGithub = "Authors-module--authorGithub--r1yj1";
export var authorLinkedIn = "Authors-module--authorLinkedIn--XoYSo";
export var authorPreviewImage = "Authors-module--authorPreviewImage--neX3X";
export var authorsBox = "Authors-module--authorsBox--HvmUH";
export var authorsContainerWrapper = "Authors-module--authorsContainerWrapper--OwXpO";
export var authorsMainArticles = "Authors-module--authorsMainArticles--FF001";
export var authorsMainContainer = "Authors-module--authorsMainContainer--tUa29";
export var backgroundCircles = "Authors-module--backgroundCircles--b4HG7";
export var backgroundCirclesBlogArticles = "Authors-module--backgroundCirclesBlogArticles--6d-WM";
export var blogsCircleBlueRight = "Authors-module--blogsCircleBlueRight--qDorr";
export var contactOutlineCircle = "Authors-module--contactOutlineCircle--sGJFu";