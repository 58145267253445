import React, { FC } from "react";
import Layout from "../components/Layout/Layout";
import AuthorsMain from "../components/AuthorsUiComponents/Authors";
import { graphql } from "gatsby";
import { SEO } from "../components/seo";

type Props = {
  location: any;
  data: any;
  pageContext: any;
};

const Reports: FC<Props> = ({ data, pageContext }) => {
  return (
    <div>
      <Layout location="/blog">
        <SEO
          title="Our team"
          description="We are a team of passionate cloud enthusiasts building cloud-native applications for our friends!"
          type="website"
        />
        <>
          <AuthorsMain data={data} />
        </>
      </Layout>
    </div>
  );
};

export const query = graphql`
  query AllAuthorQuery {
    allSanityAuthor(filter: { activeMember: { eq: "Yes" } }) {
      edges {
        node {
          _rawImage(resolveReferences: { maxDepth: 20 })
          id
          image {
            asset {
              gatsbyImageData(fit: FILLMAX)
            }
          }
          alt
          name
          position
          linkedin
          github
        }
      }
    }
  }
`;

export default Reports;
