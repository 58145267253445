import React, { FC } from "react";
import * as styles from "./Authors.module.scss";
import { Link } from "gatsby";
import CircleBlur from "../Shared/CircleOutlineAndBlur/CircleBlur";
import CircleOutline from "../Shared/CircleOutlineAndBlur/CircleOutline";
import buttonRight from "../../images/buttonArrowRight.svg";
import { GatsbyImage } from "gatsby-plugin-image";
import InnerWrapper from "../Shared/InnerWrapper/InnerWrapper";
import { pathWithoutSpace } from "../ReportsUiComponents/ReportsMainHelpers";
import { FaLinkedinIn, FaGithub } from "react-icons/fa";
import { UseImageUrl } from "../hooks/UseImageUrl";
import { authorSorter } from "../../components/Shared/HelperFunctions/AuthorSortHelper";
import CircleGreenBlur from "../Shared/CircleOutlineAndBlur/Circlegreenblur";
type Props = {
  data: any;
};

const AuthorsMain: FC<Props> = ({ data }) => {
  return (
    <div className={styles.authorsContainerWrapper}>
      <div className={styles.authorsMainContainer}>
        <h1 className="heading">
          <span>.</span>Our team
        </h1>
        <p>
          We’re a growing team of cloud enthusiasts, specialists and friends —
          full of personality, diversity and passion.
        </p>
        <div className={styles.backgroundCircles}>
          <CircleOutline
            isBlurVisible={false}
            initialScale={0.3}
            animateScale={1}
            transitionType={"easeIn"}
            transitionDelay={0.2}
            transitionDuration={1}
            circleStyleClass={styles.contactOutlineCircle}
          />
          <CircleGreenBlur
            transitionType={"easeIn"}
            transitionDelay={0.2}
            transitionDuration={1}
            circleStyleClass={styles.aboutMainCircleBlurGreen}
          />

          <CircleBlur
            initialOpacity={0}
            animateOpacity={0.1}
            transitionType={"easeIn"}
            transitionDelay={0.2}
            transitionDuration={1}
            circleStyleClass={styles.aboutMainCircleBlurBlue}
          />
          <CircleGreenBlur
            transitionType={"easeIn"}
            transitionDelay={0.2}
            transitionDuration={1}
            circleStyleClass={styles.aboutMainCircleBlurGreenupper}
          />
          <CircleGreenBlur
            transitionType={"easeIn"}
            transitionDelay={0.2}
            transitionDuration={1}
            circleStyleClass={styles.aboutMainCircleBlurGreenlower}
          />
        </div>
      </div>

      <InnerWrapper>
        <div className={styles.authorsMainArticles}>
          {data.allSanityAuthor.edges
            ?.sort(authorSorter)
            .map(({ node }: any, index: number) => {
              const path = pathWithoutSpace(node.name);
              UseImageUrl(node.image, node._rawImage);
              return (
                <div
                  key={index}
                  className={`${styles.authorsBox} gatsby-img-author`}
                >
                  <Link to={`/our-team/${path}`}>
                    <GatsbyImage
                      image={node.image.asset.gatsbyImageData}
                      alt={node?.alt || "Author"}
                      className={styles.authorPreviewImage}
                    />

                    <h3>{node.name}</h3>
                    <p>{node.position}</p>
                  </Link>

                  <div className={styles.authorLinkedIn}>
                    <a href={node.linkedin} target="_blank" rel="noreferrer">
                      <FaLinkedinIn color="#ffff" size={22} />
                    </a>
                    {node.github !== null && (
                      <a
                        href={node.github}
                        target="_blank"
                        rel="noreferrer"
                        className={styles.authorGithub}
                      >
                        <FaGithub color="#ffff" size={20} />
                      </a>
                    )}
                  </div>

                  <Link to={`/our-team/${path}`}>
                    <span>read more</span>&nbsp;&nbsp;
                    <img src={buttonRight} alt="Right Button" />
                  </Link>
                </div>
              );
            })}
        </div>
      </InnerWrapper>
    </div>
  );
};

export default AuthorsMain;
